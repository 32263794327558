<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>Academics</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                    <div id="publications">
                        <div class="publication">
                            <h3>An integrate medicine of Bhutan</h3>
                            <p class="author">Tenzin Jamtsho</p>
                            <p class="tag">Sowa Rigpa, Pharmacological activities, Ethnotaxonomy</p>
                            <a href="/publications/phytochemicals paper.pdf" target="_blank">Read More</a>
                        </div>                        
                    </div>  
                    <div id="publications">
                        <div class="publication">
                            <h3>The resilience of Rubiaceae to anthropogenic factors: a case study from the Himalayan range of Western Bhutan</h3>
                            <p class="author">Tenzin Jamtsho</p>
                            <p class="tag">Rubiaceae, Anthropogenic, Endemic </p>
                            <a href="/publications/The_resilience_of_Rubiaceae_to_anthropogenic_facto.pdf" target="_blank">Read More</a>
                        </div>
                        
                    </div>         
                 </div>
              
          <div class="sidebar">
              <AcademicsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AcademicsQuickLinks from "../../components/page/AcademicsQuickLinks.vue";
import Department from '@/assets/data/Departments.json'
import Footer from "../../components/page/Footer.vue";


export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AcademicsQuickLinks, Footer },
    setup(){
        const title= ref('Publications')
        return {title}
    },
    data(){
        return {
            Departments: Department,
           
        }
    }
  
}
</script>

<style scoped>
.page-content #publications{
    padding: 0.5rem 0;
    
}
.page-content #publications h3{
    font-size: 1rem;
}
.page-content #publications a{
    text-decoration: none;
    color: var(--footer);
    transition: 0.5s ease all;
    font-weight: 600;
}
.page-content #publications a:hover{
    color: var(--primary);
}
.page-content #publications .author{
    font-style:italic;
    font-size: 0.9rem;
}
.page-content #publications .tag{
    font-size: 0.8rem;
    color: var(--secondary);
}
</style>